import React from "react";

import MyLink from "../MyLink";

import { Props } from "../../types/Card02";
import { WpImage } from "../WpImage";
import { textOmit } from "../textOmit";

/**
 * 見出しはh3をメインに想定
 * 製品一覧に使用
 */
export const Presenter: React.VFC<Props> = ({
  heading,
  link,
  description,
  img,
  HeadTagName = "h3",
}) => {
  return (
    <li
      className="relative z-10 cursor-pointer list-none overflow-hidden rounded-2xl 
      shadow-[5px_5px_30px_rgba(43,130,63,0.1)] duration-300 before:absolute before:top-0 before:left-0 before:-z-10 before:h-full
      before:w-full before:rounded-2xl before:duration-300 before:content-[''] hover:opacity-[.99]
      hover:before:border hover:before:border-solid hover:before:border-primary"
    >
      <MyLink to={link}>
        {/* 画像 */}
        {/*  local:pt-[75%] 本番： h-[233px] pc:h-[275px] */}
        <div className="relative h-[233px] w-full overflow-hidden rounded-t-2xl pc:h-[275px]">
          <WpImage
            image={img}
            className="absolute top-0 left-0 h-full w-full"
          />
        </div>
        <div className="p-6">
          <HeadTagName className="text-base font-bold text-dark">
            {textOmit(30, description || "")}
          </HeadTagName>
          <p className="mt-3 text-sm text-primary">{heading}</p>
        </div>
      </MyLink>
    </li>
  );
};
