import React from "react";

import { Props } from "../../types/Slider03";

// import Swiper core and required modules
import { Autoplay, EffectFade } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

/**
 * スライダーを返します。左右のナビゲーションとページネーションはなし
 * top fv用に作りました。
 * 詳しくはswiper公式を参照。
 */
export const Presenter: React.FC<Props> = ({ children }) => {
  // 配列化しておく
  const childrenArray = children && Object.values(children);

  // スライダーの中身1つの時を判定、1つの時は配列の先頭がsymbolになることで判定
  const isChildOne = typeof (childrenArray && childrenArray[0]) === "symbol";

  // 子要素全てnullか判定
  const isChildrenNull =
    childrenArray && childrenArray.every((item) => item === null);

  // 子要素全てnullの場合、スライダーの中身1つの時は何も表示させない。
  if (isChildrenNull || isChildOne) {
    return null;
  }

  return (
    <div className="slider03 relative">
      <Swiper
        // install Swiper modules
        modules={[Autoplay, EffectFade]}
        autoplay={{ delay: 5000 }}
        speed={1000}
        loop={true}
        effect="fade"
        fadeEffect={{ crossFade: true }}
      >
        {childrenArray &&
          childrenArray.map(
            (child, index) =>
              child && <SwiperSlide key={index}>{child}</SwiperSlide>
          )}
      </Swiper>
    </div>
  );
};
