import React from "react";

import { Props } from "../../types/NewsList01";
// import MyLink from "../MyLink";
import * as NewsList01Style from "./NewsList01.module.scss";

/**
 * お知らせの行を返します、aタグは色と下線がつきます。
 */
export const Presenter: React.VFC<Props> = ({ date, news }) => {
  return (
    <li className="relative list-none border-b border-solid border-[#E0E0E0]">
      <div className="flex flex-col py-10 pc:flex-row pc:items-center pc:py-12 pc:pr-4">
        <div className="text-sm font-semibold text-primary">{date}</div>
        <p
          className={`mt-2 text-base text-dark pc:mt-0 pc:ml-[50px] ${NewsList01Style.linkText}`}
          dangerouslySetInnerHTML={{
            __html: news,
          }}
        ></p>
      </div>
    </li>
  );
};
