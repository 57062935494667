import React from "react";

import { Props } from "../../types/ItemInfo";
import { Button01 } from "../Button01";
import { WpImage } from "../WpImage";

/**
 * 製品情報、トップスライダーに使用
 */
export const Presenter: React.VFC<Props> = ({
  title,
  catchtext,
  text,
  img,
  labels,
  link01,
  link02,
}) => {
  return (
    <li className="mx-auto max-w-full pc:max-w-[576px]">
      <div className="text-center">
        <WpImage image={img} className="w-[255px] pc:w-[405px]" />
      </div>
      <div className="mt-6 text-center text-base font-bold pc:mt-10 pc:text-xl">
        {title}
      </div>
      {/* 角丸影付きブロック */}
      <div
        className="relative mx-auto mt-[36px] w-[310px] rounded-[10px] bg-white p-8 drop-shadow-[5px_5px_15px_rgba(43,130,63,0.1)]
       before:absolute before:top-0 before:left-1/2 before:h-10 before:w-[1px] before:-translate-y-1/2 before:bg-primary tb:w-[500px] pc:mt-8 pc:pt-12"
      >
        <div
          className="text-center text-base font-bold pc:text-xl"
          dangerouslySetInnerHTML={{ __html: catchtext || "" }}
        ></div>
        <ul className="mt-3 flex justify-center gap-x-3 gap-y-[6px]">
          {labels?.map(
            (label, index) =>
              label && (
                <li
                  className="inline-block border border-solid border-grey px-3 py-[6px] text-xs font-medium text-grey"
                  key={index}
                >
                  {label.name}
                </li>
              )
          )}
        </ul>
        {text && (
          <p
            className="mt-6 text-center text-sm pc:text-base"
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        )}
      </div>
      {/* 角丸影付きブロックここまで */}
      <div className="mx-auto mt-6 flex flex-col items-center justify-center gap-y-3 tb:flex-row tb:gap-x-4 pc:mt-8">
        <Button01
          label="この製品を見る"
          link={link01 || "/"}
          isWhite={true}
          isPageTransition={true}
        />
        <Button01
          label="製品一覧を見る"
          link={link02 || "/products"}
          isWhite={true}
          isPageTransition={true}
        />
      </div>
    </li>
  );
};
