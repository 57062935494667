import React, { useState } from "react";

import { Props } from "../../types/FAQ01";

/**
 * よくある質問に使用するアコーディオンです。
 */
export const Presenter: React.VFC<Props> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      className={`relative cursor-pointer border-b border-solid border-[#E0E0E0] py-12`}
    >
      <dt
        className={
          `relative cursor-pointer pl-8 pr-16 text-lg font-bold text-dark
        before:absolute before:right-0.5 before:top-[calc(50%-1px)] before:h-0.5 before:w-7
          before:bg-primary before:content-['']
          after:absolute after:right-0.5 after:top-[calc(50%-1px)] after:h-0.5 after:w-7
          after:bg-primary after:duration-300 after:content-[''] ` +
          (isOpen || `after:rotate-[90deg]`)
        }
      >
        <span
          className={`absolute top-[40%] left-0 -translate-y-1/2 text-[32px]
          text-primary`}
        >
          Q
        </span>
        {question}
      </dt>
      <dd
        className={
          `relative mt-6 pl-8 pr-16 text-lg text-dark duration-300 ` +
          (isOpen ? `h-auto` : `visibility: hidden; mt-0 h-0 opacity-0`)
        }
      >
        <span
          className={`absolute top-0 left-0 text-2xl
          text-primary`}
        >
          A
        </span>
        <div
          dangerouslySetInnerHTML={{
            __html: answer ? answer : "",
          }}
        />
      </dd>
    </div>
  );
};
