import { StaticImage } from "gatsby-plugin-image";
import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Button01 } from "../components/Button01";
import { Heading02 } from "../components/Heading02";
import { ItemInfo } from "../components/ItemInfo";
import { Layout } from "../components/Layout/index";
import { Slider01 } from "../components/Slider01";
import { Slider03 } from "../components/Slider03";
import { Card02 } from "../components/Card02";
import { NewsList01 } from "../components/NewsList01";
import * as style from "../styles/top.module.scss";
import { FAQ01 } from "../components/FAQ01";
import { WpImage } from "../components/WpImage";
import MyLink from "../components/MyLink";
import { Triangle } from "../components/Triangle";
import TextLoop from "../components/TextLoop";

const IndexPage: React.VFC<PageProps<GatsbyTypes.IndexQuery>> = ({ data }) => {
  const { allWpCase, allWpPost, allWpFaq, carstopper, slopecorner } = data;

  return (
    <Layout>
      {/* mv */}
      <div className="w-full bg-bggrey pt-12 pb-[40px] pc:pt-[70px]">
        <div className="l-container flex flex-col-reverse pc:flex-row pc:justify-between">
          <div className="mt-10 w-full pc:mt-[45px] pc:w-[670px]">
            <h1 className="">
              <span className="block text-[32px] font-bold leading-[40px] tracking-[0.13em] pc:text-[64px] pc:leading-[70px] pc:tracking-[0.15em]">
                あらゆる段差を解消
              </span>
              <span className="block text-[20px] font-bold leading-[70px] tracking-[0.072em] text-primary pc:text-[32px] pc:leading-[86px]">
                ミスギの段差スロープ
              </span>
            </h1>
            <p className="ml-[-0.5em] text-[16px] leading-[38px] tracking-[0.048em]">
              「街・車・人に優しく安心・安全で快適な生活環境をPRODUCE」
            </p>
            <p className="text-[16px] leading-[38px] tracking-[0.048em]">
              弊社は「品質主義」を第一とし、安心・安全で快適な生活環境創りを
              <br className="hidden pc:block" />
              サポートする事で社会的責任を果たします
            </p>
          </div>
          <div className="w-full pc:w-[405px]">
            <ul>
              <Slider03>
                {slopecorner.nodes.map(
                  (node) =>
                    node.slug && (
                      <li key={node.slug}>
                        <MyLink to={`/products/${node.slug}`}>
                          <p className="pl-9 text-[12px] font-semibold tracking-[0.021em] text-primary pc:pl-0 pc:text-[14px]">
                            {node.productAcf?.enname}
                          </p>
                          <p className="pl-9 text-[12px] font-medium tracking-[0.048em] text-primary pc:pl-0">
                            {node.title}
                          </p>
                          <div
                            className={`mx-auto mt-4 w-[280px] pc:mt-6 pc:w-[405px]`}
                          >
                            <WpImage image={node.featuredImage?.node} />
                          </div>
                        </MyLink>
                      </li>
                    )
                )}
              </Slider03>
            </ul>
          </div>
        </div>
      </div>

      {/* features */}
      <div className="py-10 pc:pt-[140px] pc:pb-[160px]">
        <div className="l-container relative">
          <Heading02 label="私たちの特長" bgLabel="FEATURES" />
          <div className="mt-4 pc:mt-10">
            <p className="text-2xl pc:text-3xl">
              段差を解消する
              <br />
              <strong className="text-[40px] pc:text-[64px]">
                だけじゃない
              </strong>
            </p>
            <p className="mt-6 text-2xl font-bold pc:text-3xl">
              44年間こだわり抜いた
            </p>
            <strong className="mt-2 inline-block bg-primary p-1 text-[40px] font-bold text-white pc:py-4 pc:pl-4 pc:pr-6 pc:text-[64px]">
              品質の高さが武器
            </strong>
          </div>
          <div className="z-10 mx-auto mt-8 max-w-[335px] pc:absolute pc:left-[52%] pc:top-[270px] pc:h-auto pc:max-w-[692px]">
            <StaticImage
              className="z-10 overflow-hidden rounded-2xl"
              src="../images/IMG_0037.png"
              alt=""
            />
          </div>
          <p className="mt-8 text-lg pc:mt-14 pc:max-w-[572px]">
            安心安全で高品質な日本製
            <br />
            弊社では1979年創業当初から、何よりも高品質にこだわり、現在も創業精神を引継ぎ、皆様に少しでも長く安心・安全・快適にご使用頂けるよう、より良い商品の開発と品質維持を心掛けております。
          </p>
          <div className="mt-8 text-center pc:mt-10 pc:text-left">
            <Button01
              label="詳細を見る"
              link="/features"
              isWhite={true}
              isPageTransition={true}
            />
          </div>
          <Triangle className="top-[110px] right-0 h-[318px] w-[212px] pc:top-0 pc:right-[-109px] pc:h-[707px] pc:w-[471px]" />
          <Triangle className="bottom-[60px] left-[5px] h-[318px] w-[212px] pc:bottom-[-64px] pc:left-[-326px] pc:h-[707px] pc:w-[471px]" />
        </div>
      </div>

      {/* products */}
      <div className="relative">
        {/* 左緑の部分 */}
        <div className="absolute top-0 left-0 h-full w-7 bg-primary pc:w-[calc(50%-760px+370px)]">
          <div className="ml-auto flex h-full w-32 items-end">
            <TextLoop
              text="Step Professional"
              className="hidden w-[3285px] flex-shrink-0 origin-bottom-left translate-x-[120px] -rotate-90 tracking-[0.048em] text-primary pc:block pc:text-[104px]"
              design={3}
            />
          </div>
        </div>
        <div className="mx-auto max-w-[1520px]">
          <div className="pl-12 pc:pl-[calc(370px+95px)]">
            <Heading02 label="製品一覧" bgLabel="PRODUCTS" />
            <p className="mt-7 text-xl font-bold pc:text-3xl">
              <strong className="text-[32px] font-bold tracking-normal text-primary pc:text-[56px]">
                強度・
              </strong>
              <strong className="text-[32px] font-bold tracking-normal text-primary pc:text-[56px]">
                耐久性・
              </strong>
              <strong className="text-[32px] font-bold tracking-normal text-primary pc:text-[56px]">
                静音性
              </strong>
              では負けません。
            </p>
            <p className="text-xl font-bold pc:text-3xl">
              <strong className="text-[32px] font-bold tracking-[0.072em] text-primary pc:text-[56px]">
                OEM製品
              </strong>
              <br className="sp" />
              もお気軽にご相談ください。
            </p>

            {/* スライダー1 */}
            <div className="">
              <div className="-ml-10 text-center pc:ml-0">
                <div
                  style={{
                    textShadow:
                      "1px 1px 0 #2B823F,-1px -1px 0 #2B823F,1px -1px 0 #2B823F,-1px 1px 0 #2B823F",
                  }}
                  className="bg-clip-text text-[100px] font-bold text-white pc:text-[136px]"
                >
                  01
                </div>
                <h3 className="-mt-4 text-xl  font-bold text-primary pc:-mt-8 pc:text-2xl">
                  スロープ・コーナー
                </h3>
                <p className="text-sm font-bold pc:text-base">全9種</p>
              </div>
              <div className={`mx-auto mt-6 tb:max-w-[700px] pc:max-w-[576px]`}>
                <Slider01 slidePerViews={[1, 1, 1]}>
                  {slopecorner.nodes.map(
                    (node, index) =>
                      node.title && (
                        <ItemInfo
                          key={index}
                          title={node.title}
                          catchtext={node.productAcf?.catch}
                          img={node.featuredImage?.node}
                          labels={node.tags?.nodes}
                          text={node.productAcf?.topcatch}
                          link01={`/products/${node.slug}`}
                        />
                      )
                  )}
                </Slider01>
              </div>
            </div>

            {/* スライダー2 */}
            <div className="mt-[72px] pc:mt-[88px]">
              <div className="text-center">
                <div
                  style={{
                    textShadow:
                      "1px 1px 0 #2B823F,-1px -1px 0 #2B823F,1px -1px 0 #2B823F,-1px 1px 0 #2B823F",
                  }}
                  className="bg-clip-text text-[100px] font-bold text-white pc:text-[136px]"
                >
                  02
                </div>
                <h3 className="-mt-6 text-xl  font-bold text-primary pc:-mt-8 pc:text-2xl">
                  カーストッパー
                </h3>
                <p className="text-sm font-bold pc:text-base">全4種</p>
              </div>
              <div className="mx-auto mt-6 tb:max-w-[700px] pc:max-w-[576px]">
                <Slider01 slidePerViews={[1, 1, 1]}>
                  {carstopper.nodes.map(
                    (node, index) =>
                      node.title && (
                        <ItemInfo
                          key={index}
                          title={node.title}
                          catchtext={node.productAcf?.catch}
                          img={node.featuredImage?.node}
                          labels={node.tags?.nodes}
                          text={node.productAcf?.topcatch}
                          link01={`/products/${node.slug}`}
                        />
                      )
                  )}
                </Slider01>
              </div>
            </div>

            {/* その他の製品 */}
            <div className="mt-[72px] pb-12 pc:mt-[88px] pc:pb-[120px]">
              <div className="text-center">
                <div
                  style={{
                    textShadow:
                      "1px 1px 0 #2B823F,-1px -1px 0 #2B823F,1px -1px 0 #2B823F,-1px 1px 0 #2B823F",
                  }}
                  className="bg-clip-text text-[100px] font-bold text-white pc:text-[136px]"
                >
                  03
                </div>
                <h3 className="-mt-6 text-xl  font-bold text-primary pc:-mt-8 pc:text-2xl">
                  その他
                </h3>
              </div>
              <div className="mx-auto mt-10 pc:mt-12 pc:max-w-[840px]">
                {/* サイクルポジション */}
                <div className="flex flex-col items-center">
                  <div className="max-w-[200px] pt-10 pc:max-w-[382px]">
                    <StaticImage
                      src="../images/cycle2.png"
                      alt="サイクルポジション"
                    />
                  </div>
                  <p className="mt-6 text-center text-base font-bold pc:mt-8 pc:text-xl">
                    サイクルポジション
                  </p>
                  <div className="mt-6 pc:mt-8">
                    <Button01
                      label="この製品を見る"
                      link={"/products/product196"}
                      isWhite={true}
                      isPageTransition={true}
                      className={`font-medium`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  OEM PRODUCT */}
      <div className="relative">
        <div className="mx-auto flex flex-col items-center px-8 pt-24 pb-12 tb:max-w-[500px] pc:max-w-[100%] pc:flex-row pc:p-0">
          <div className="hidden flex-shrink-0 pc:block pc:w-1/2">
            <StaticImage
              className="z-10 overflow-hidden rounded-2xl"
              src="../images/oemproduct.png"
              alt=""
            />
          </div>
          {/* 文字 OEM PRODUCT*/}
          <div className="pt-14 pb-16 pc:relative pc:w-1/2">
            <TextLoop
              className="absolute top-0 left-[-2rem] w-[100vw] pc:top-[0px] pc:left-0 pc:w-full"
              text="OEM PRODUCT"
              design={1}
            />
            <div className="relative pc:pl-[88px]">
              <ul className="flex gap-x-4">
                {["鋳物", "アルミ", "プラスチック", "ゴム製品"].map(
                  (text, index) => (
                    <li
                      key={index}
                      className="inline-block bg-primary px-3 py-2 text-xs text-white pc:text-base"
                    >
                      {text}
                    </li>
                  )
                )}
              </ul>
              <h2 className="text-xl font-bold pc:text-3xl">
                <strong className="text-[40px] font-bold text-primary pc:text-[56px]">
                  <span className="text-[50px] font-semibold pc:text-[68px]">
                    OEM
                  </span>
                  製品
                </strong>
                <br className="pc:hidden" />
                もご相談ください
              </h2>
              <div className="mt-8 w-[335px] max-w-full pc:hidden">
                <StaticImage
                  className="z-10 overflow-hidden rounded-2xl"
                  src="../images/oemproduct.png"
                  alt=""
                />
              </div>
              <p className="mt-8 text-lg pc:max-w-[580px]">
                弊社が製品化のお手伝いをさせて頂きます。
                <br />
                弊社では鋳鉄・アルミ・プラスチック・ゴムとあらゆる素材を使用した製品の開発から製造まで一貫してお手伝いする事が可能です。
                <br />
                長年培ってきた豊富なノウハウと確かな技術でご満足いただける商品をご提供致します。
              </p>
              <div className="mx-auto mt-10 flex flex-col items-center gap-y-3 pc:mt-6 pc:flex-row pc:gap-x-4">
                <Button01
                  label="相談しにいく"
                  link={"/contact"}
                  isWhite={false}
                  isPageTransition={true}
                  className={`font-medium`}
                />
                <Button01
                  label="ミスギの特長を知る"
                  link={"/features"}
                  isWhite={true}
                  isPageTransition={true}
                  className={`font-medium`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* case */}
      <div className="bg-bggrey pt-8 pb-16 pc:pt-20">
        <div className="l-container-sm">
          <Heading02 label="施工事例" bgLabel="CASE" isWhite={true} />
          <ul className="mt-8 pc:mt-10">
            <Slider01>
              {allWpCase.nodes.map(
                (node, index) =>
                  node.title && (
                    <Card02
                      key={index}
                      heading={node.title}
                      link={`/case/${node.slug}`}
                      description={node.caseAcf?.catch}
                      img={node.featuredImage?.node}
                    />
                  )
              )}
            </Slider01>
          </ul>
          <div className="mt-10 text-center">
            <Button01
              label="一覧を見る"
              link="/case"
              isWhite={true}
              isPageTransition={true}
            />
          </div>
        </div>
      </div>

      {/* news */}
      <div className="mt-[72px] mb-24 pc:mt-[88px]">
        <div className="l-container-sm">
          <Heading02 label="最新ニュース" bgLabel="NEWS" />
          <ul className="mt-0 mb-16 pc:mb-0">
            {allWpPost.nodes.map(
              (node) =>
                node.title && (
                  <NewsList01
                    key={node.title}
                    date={node.date || "日付不明"}
                    news={node.title}
                  />
                )
            )}
          </ul>
          <div className="mt-10 text-center">
            <Button01
              label="一覧を見る"
              link="/news"
              isWhite={true}
              isPageTransition={true}
            />
          </div>
        </div>
      </div>

      {/* faq */}
      <div className="mt-[72px] mb-24 pc:mt-[88px]">
        <div className="l-container-sm">
          <Heading02 label="よくあるご質問" bgLabel="FAQ" />
          <ul className="mt-8 mb-16 pc:mb-0">
            {allWpFaq.nodes.map(
              (node) =>
                node.title && (
                  <FAQ01
                    key={node.title}
                    question={node.title}
                    answer={node.content}
                  />
                )
            )}
          </ul>
          <div className="mt-10 text-center">
            <Button01
              label="一覧を見る"
              link="/faq"
              isWhite={true}
              isPageTransition={true}
            />
          </div>
        </div>
      </div>

      {/* recruit */}
      <div className={`${style.recruit} pb-[72px]`}>
        <div className="l-container-sm relative">
          <Heading02 label="採用情報" bgLabel="RECRUIT" />
          <div className="flex flex-col pc:flex-row pc:pr-16">
            <div className="z-10 mt-8 pc:absolute pc:top-[60px] pc:right-[-13vw] pc:mt-0 pc:flex pc:w-[33vw]">
              <StaticImage
                className="overflow-hidden rounded-2xl"
                src="../images/recruit.png"
                alt=""
              />
            </div>
            <div className="mt-8 pc:mt-10 pc:mb-[100px] pc:max-w-[600px]">
              <span className="text-sm text-primary pc:text-base">
                私たちと一緒に働いてくれる人を探しています！
              </span>
              <h3 className="mt-4 text-xl font-bold pc:text-3xl">
                「バリアフリー」が当たり前の社会に
              </h3>
              <p className="mt-3 text-lg pc:mt-3">
                あなたの思いとあなたの力で明るい未来がここから始まります。
              </p>
              <div className="mt-10 text-center pc:mt-8 pc:text-left">
                <Button01
                  label="詳細を見る"
                  link="/recruit"
                  isWhite={true}
                  isPageTransition={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Index {
    slopecorner: allWpProduct(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "slopecorner" } } } }
      }
    ) {
      nodes {
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        title
        slug
        productAcf {
          enname
          catch
          topcatch
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
    carstopper: allWpProduct(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "carstopper" } } } }
      }
    ) {
      nodes {
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        title
        slug
        productAcf {
          enname
          catch
          topcatch
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpCase(sort: { fields: date, order: ASC }) {
      nodes {
        title
        slug
        caseAcf {
          catch
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "YYYY.MM.DD")
      }
    }
    allWpFaq(limit: 3, sort: { fields: date, order: ASC }) {
      nodes {
        title
        content
      }
    }
  }
`;
