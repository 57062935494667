// 文字数制限する関数
// 引数でcontent.textContentを受け取る。
export function textOmit($length: number, $string: string): string {
  // 制限文字数を設定
  const MAX_LENGTH = $length;

  // もしstringの文字数がMAX_LENGTHより大きかったら末尾に...を付け足して返す。
  if ($string.length > MAX_LENGTH) {
    // substr(何文字目からスタートするか, 最大値);
    return $string.substr(0, MAX_LENGTH) + "...";
  }
  //文字数がオーバーしていなければそのまま返す
  return $string;
}
