import React from "react";

import { Props } from "../../types/Slider01";

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, Controller } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

// import { Card01 } from "../Card01";

/**
 * スライダーを返します。左右のナビゲーションとページネーションもついています。
 * 親でmax-width指定しmx-autoで横幅決められます。
 * 詳しくはswiper公式を参照。
 */
export const Presenter: React.FC<Props> = ({
  slidePerViews = [1, 2, 3],
  children,
}) => {
  // 配列化しておく
  const childrenArray = children && Object.values(children);

  // スライダーの中身1つの時を判定、1つの時は配列の先頭がsymbolになることで判定
  const isChildOne = typeof (childrenArray && childrenArray[0]) === "symbol";

  // 子要素全てnullか判定
  const isChildrenNull =
    childrenArray && childrenArray.every((item) => item === null);

  // 子要素全てnullの場合、スライダーの中身1つの時は何も表示させない。
  if (isChildrenNull || isChildOne) {
    return null;
  }

  return (
    <div className="relative mx-auto mb-16">
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay, Controller]}
        breakpoints={{
          320: { slidesPerView: slidePerViews[0], spaceBetween: 20 },
          750: { slidesPerView: slidePerViews[1], spaceBetween: 20 },
          1000: { slidesPerView: slidePerViews[2], spaceBetween: 20 },
        }}
        pagination={{
          el: ".my-swiper-pagination",
          bulletActiveClass: "bg-primary",
          bulletClass:
            "border border-solid border-primary bg-white w-2.5 h-2.5 inline-block rounded-full mx-1.5",
          clickable: true,
        }}
        navigation={{
          prevEl: ".my-swiper-prev",
          nextEl: ".my-swiper-next",
        }}
        autoplay={{ delay: 5000 }}
        speed={1000}
        loop={true}
      >
        {childrenArray &&
          childrenArray.map(
            (child, index) =>
              child && <SwiperSlide key={index}>{child}</SwiperSlide>
          )}
      </Swiper>
      {/* ページネーション */}
      <div
        style={{
          left: "50%",
          bottom: "-64px",
          transform: "translateX(-50%)",
          width: "auto",
        }}
        className="my-swiper-pagination absolute"
      ></div>
      {/* 前に戻るボタン */}
      <div
        className="my-swiper-prev absolute top-1/2 right-auto z-10 hidden h-[60px] w-[60px] cursor-pointer justify-center rounded-[50%] bg-white drop-shadow-[0_0px_25px_rgba(43,130,63,0.2)] before:absolute before:left-[calc(50%-0.2rem)] before:top-[calc(50%-0.4rem)]
        before:h-3.5 before:w-3.5 before:-rotate-[135deg] before:border-t before:border-r before:border-primary before:content-[''] tb:-left-[64px] tb:flex pc:-left-[100px]"
      ></div>
      {/* 次に進むボタン */}
      <div
        className="my-swiper-next absolute top-1/2 left-auto z-10 hidden h-[60px] w-[60px] cursor-pointer justify-center rounded-[50%] bg-white drop-shadow-[0_0px_25px_rgba(43,130,63,0.2)] before:absolute before:right-[calc(50%-0.2rem)]
      before:top-[calc(50%-0.4rem)] before:h-3.5 before:w-3.5 before:rotate-45 before:border-t before:border-r before:border-primary before:content-[''] tb:-right-[64px] tb:flex pc:-right-[100px]"
      ></div>
    </div>
  );
};
