import React from "react";

import * as styles from "./Triangle.module.scss";

type Props = {
  className?: string;
};

/**
 * 三角形
 * パラメーターにサイズを指定
 */
export const Triangle: React.VFC<Props> = ({ className }) => {
  return (
    <div className={`absolute z-[-1] ${className} ${styles.sankaku}`}></div>
  );
};
