import React from "react";

import { Props } from "../../types/Heading02";

/**
 * 見出し2、h2をメインに想定
 */
export const Presenter: React.VFC<Props> = ({
  label,
  TagName = "h2",
  bgLabel,
  isWhite = false,
}) => {
  return (
    <TagName className="relative z-10 pt-9 pl-1 pb-5 text-sm font-bold text-primary pc:pl-2 pc:pt-20 pc:pb-10 pc:text-xl">
      {label}
      <span
        className={`absolute top-0 left-0 -z-10 inline-block text-[64px] font-semibold leading-none pc:text-[124px] ${
          isWhite ? `text-white` : `text-[rgb(244,244,244)]`
        } `}
      >
        {bgLabel}
      </span>
    </TagName>
  );
};
